  /* .hidden-xxs */
  @media only screen and (max-width: 368px) {
    .hidden-xxs {
      display: none !important;
    }
  }

  /* .hidden-xs */
  @media only screen and (max-width: 576px) {
    .hidden-xs {
      display: none !important;
    }
  }

  /* .hidden-sm */
  @media only screen and (max-width: 768px) {
    .hidden-sm {
      display: none !important;
    }
  }

  /* .hidden-md */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hidden-md {
      display: none !important;
    }

    .image-size {
      width: 800px !important;
      margin-top: -200px !important;
    }
  }

  /* .hidden-lg */
  @media only screen and (min-width: 992px) {
    .hidden-lg {
      display: none !important;
    }

    .image-size {
      width: 900px !important;
      margin-top: -260px !important;
    }

  }

  /* .hidden-xl */
  @media only screen and (min-width: 1200px) {
    .hidden-xl {
      display: none !important;
    }
    
    .image-size {
      width: 1100px !important;
      margin-top: -260px !important;
    }

  }

  /* .hidden-xxl */
  @media only screen and (min-width: 1441px) {
    .hidden-xxl {
      display: none !important;
    }
    
    .image-size {
      width: 1350px !important;
      margin-top: -280px !important;
    }

  }