html, body {
  max-width: 100vw !important;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
    
.modal-open {
  filter: opacity(10%); /* Adjust the blur intensity as needed */
  pointer-events: none; /* Allow clicks through the blurred background */
}

.modal {
  pointer-events: auto; /* Enable clicks on the modal */
}

.title-underline {
  border: 0;
  border-bottom: 4px solid #fcc861;
  width: 5rem;
  margin: auto;
}

.title-underline-light {
  border: 0;
  border-bottom: 4px solid #fffaef;
  width: 5rem;
  margin: auto;
}

.floating {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.max-width {
  max-width: 1080px !important;
  margin: 0 auto !important;
  float: none !important;
}

.max-width-email {
  max-width: 700px !important;
  margin: 0 auto !important;
  float: none !important;
}

.hero-about {
  position: relative;
  background-image: linear-gradient(0deg, rgba(182, 128, 46, 0.7), rgba(138, 110, 19, 0.7)), url('./img/aboutus.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-contact {
  position: relative;
  background-image: linear-gradient(0deg, rgba(182, 128, 46, 0.7), rgba(138, 110, 19, 0.7)), url('./img/contact.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-section {
  position: relative;
  height: 100vh;
  background-image: linear-gradient(0deg, rgba(182, 128, 46, 0.7), rgba(138, 110, 19, 0.7)), url('./img/hero.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-section {
  position: relative;
  height: 100vh;
  background-image: linear-gradient(0deg, rgba(182, 128, 46, 0.8), rgba(138, 110, 19, 0.8)), url('./img/suurpelto.png');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-section {
  position: relative;
  background-image: linear-gradient(0deg, rgba(182, 128, 46, 0.8), rgba(138, 110, 19, 0.8)), url('./img/howitworks.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 5px;
}

.jumbotron {
  background-image: linear-gradient(0deg, rgba(182, 128, 46, 0.7), rgba(138, 110, 19, 0.7)), url('./img/office.jpg');
}

.brand-bg {
  background-color: #fcc861 !important;
}

.brand-text {
  color: #fcc861 !important;
}

a {
  color: #fcc861 !important;
}

a:hover {
  color: #ba800e !important;
}